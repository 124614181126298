import React from 'react';

const Header = () => {
    return (
        <header className="bg-primary text-white text-center py-5">
            <img src="/logo/logo512.png" alt="EdoTech Logo" className="img-fluid mb-3" style={{ maxWidth: '150px' }} />
            <h1>EdoTech</h1>
        </header>
    );
}

export default Header;
