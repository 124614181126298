import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ProjectsSection = ({ type }) => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        fetch('/content/data.json')
            .then(response => response.json())
            .then(data => {
                if (type === 'Applications') {
                    setProjects(data.applications);
                } else if (type === 'Dashboards') {
                    setProjects(data.dashboards);
                } else if (type === 'Games') {
                    setProjects(data.games);
                }
            });
    }, [type]);

    return (
        <section className="container my-5">
            <h2 className="text-center mb-4">{type}</h2>
            <div className="p-3 border rounded">
                <div className="row justify-content-center">
                    {projects.map((project, index) => (
                        <div key={index} className="col-md-4 text-center mb-4">
                            <a href={project.link} target="_blank" rel="noopener noreferrer">
                                <img src={project.icon} alt={project.title} className="img-fluid mb-2" style={{ maxWidth: '100px' }} />
                            </a>
                            <h5>{project.title}</h5>
                            <p>{project.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

ProjectsSection.propTypes = {
    type: PropTypes.string.isRequired,
};

export default ProjectsSection;
