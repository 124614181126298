import React from 'react';
import Header from './components/Header';
import ProjectsSection from './components/ProjectsSection';
import AboutSection from './components/AboutSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';

function App() {
    return (
        <div>
            <Header />
            <ProjectsSection type="Applications" />
            <ProjectsSection type="Dashboards" />
            <ProjectsSection type="Games" />
            <AboutSection />
            <ContactSection />
            <Footer />
        </div>
    );
}

export default App;
