import React from 'react';

const AboutSection = () => {
    return (
        <section className="bg-light text-center py-4">
            <div className="container">
                <h2 className="mb-4">About</h2>
                <p className="mx-auto" style={{ maxWidth: '600px', lineHeight: '1.6' }}>
                    Welcome to EdoTech! This website contains a variety of applications, games, and dashboards that I have developed. Explore the sections to discover the tools and entertainment I have created. Enjoy!
                </p>
            </div>
        </section>
    );
}

export default AboutSection;
