import React from 'react';

const ContactSection = () => {
    return (
        <section className="bg-light text-center py-4">
            <div className="container">
                <h2>Contact</h2>
                <p>Contact information goes here.</p>
            </div>
        </section>
    );
}

export default ContactSection;
